function validateUrl(url) {
    const notFound= '/404.html';
    try {
        const parsed = new URL(url);
        const isValid = ['https:', 'http:'].includes(parsed.protocol);
        return isValid ? url : notFound;
    }catch (e){
        //Thrown because of invalid URL above
        return notFound;
    }
}

export default validateUrl;