import {Tab} from "@mui/material";
import React from "react";
import withStyles from '@mui/styles/withStyles';

// TODO this should just modify the CaseTab

const AnswersTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 255,
        maxHeight: 24,
        fontWeight: theme.typography.fontWeightBold,
        marginRight: theme.spacing(4),
        fontSize: 12,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        backgroundColor: '#F8F8F8',
        borderRadius: 50,
        color: '#0E2361',
        marginBottom: 10,
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#FFFFFF',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: '#7BAFDF' ,
            borderRadius: 50,
        },
        '&:focus': {
            color: '#FFFFFF',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default AnswersTab;